export const TicketUtil = {

    cleanupFields(ticket) {
        let cleanedDispatch = { ...ticket };

        delete cleanedDispatch['Date Created'];
        delete cleanedDispatch['Date Resolved'];
        delete cleanedDispatch['Date Cancelled'];
        delete cleanedDispatch['name'];
        delete cleanedDispatch['duration'];
        delete cleanedDispatch['_showDetails'];

        return cleanedDispatch;
    }

}