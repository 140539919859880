<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by all users</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>
            <template v-slot:cell(changes)="row">
                <div class="changed-section" v-if="hasValueChanged(row.item, 'status')">
                    <b>Status</b>
                    <br>
                    From <span v-if="row.item.old !== null">
                        <UserTicketStatus :ticket="row.item.old" />
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                    to
                    <UserTicketStatus :ticket="row.item.new" />
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'remarks')">
                    <b>Remarks</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'remarks') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'remarks') }} </b>
                </div>
            </template>
            <template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
        </b-table>
    </div>
</template>

<script>
// Components
import UserTicketStatus from './UserTicketStatus.vue';

// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';

export default {
    name: 'user-ticket-change-logs-details-view',
    components: {
        UserTicketStatus
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            ticketId: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        }
    },
    watch: {
        ticketId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.ticketId = this.row.item.id;
        await this.retrieveChangeLog(this.ticketId);
    },
    methods: {
        async retrieveChangeLog(ticketId) {
            if (ticketId) {
                let param = {
                    collection: 'tickets',
                    id: ticketId,
                    userId: ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },
        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, log => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};

                // Status
                const oldStatus = oldLog.status ? oldLog.status : '';
                const newStatus = newLog.status ? newLog.status : '';

                // Remarks
                const oldRemarks = oldLog.remarks ? oldLog.remarks : '';
                const newRemarks = newLog.remarks ? newLog.remarks : '';

                return oldStatus !== newStatus || oldRemarks !== newRemarks;

            });
            this.changeLogs = filteredLogs;
        },

        hasValueChanged(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let value = oldLog[fieldName] ? oldLog[fieldName] : "-";
            return value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            let value = newLog[fieldName] ? newLog[fieldName] : "-";
            return value;
        },
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}
</style>