<template>
    <b-modal id="user-ticket-update-dialog" :title="title" ok-title="Proceed" cancel-title="Close" ref="modal"
        @ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
        :no-close-on-backdrop="true" size="md">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <div class="confirmMessage">
            Are you sure you want to {{ operation.toLowerCase() }} <strong class="numFont">{{ ticketId }}</strong>?
        </div>

        <div class="mt-2 confirmMessage">
            <b-form-group label="Request Type:" label-cols-sm="5" label-class="font-weight-bold pt-0"
                label-align-sm="right">
                {{ ticket.requestType }}
            </b-form-group>
            <b-form-group label="Date Created:" label-cols-sm="5" label-class="font-weight-bold pt-0"
                label-align-sm="right">
                {{ getFormattedDateWithTime(ticket.dateCreated) }}
            </b-form-group>
            <b-form-group label="Status:" label-cols-sm="5" label-class="font-weight-bold pt-0" label-align-sm="right">
                <UserTicketStatus :ticket="ticket" />
            </b-form-group>
            <b-form-group v-show="isOtherConcerns(ticket)" label="Other Concerns:" label-cols-sm="5"
                label-class="font-weight-bold pt-0" label-align-sm="right">
                <span class="truncate-text">
                    <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
                        type="html" :text="breakRemarks(otherConcerns, 25)" />
                </span>
            </b-form-group>
            <b-form-group v-show="previousRemarks.length > 0" label="Previous Remarks:" label-cols-sm="5"
                label-class="font-weight-bold pt-0" label-align-sm="right">
                <span class="truncate-text">
                    <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
                        type="html" :text="breakRemarks(previousRemarks, 25)" />
                </span>
            </b-form-group>
        </div>

        <hr />
        <b-form @submit.stop.prevent="handleSubmit" novalidate>
            <b-form-group label="Remarks" label-for="remarks">
                <b-form-textarea name="Remarks" type="text" v-model="ticket.remarks" maxlength="200"
                    v-validate="getValidationParam(true, remarksRegex)" :rows="3" :placeholder="remarksPlaceholder" />
                <span v-show="errors.has('Remarks')" class="help-block">
                    {{ errors.first('Remarks') }}
                </span>
            </b-form-group>
        </b-form>

        <b-row v-show="operation === 'Cancel' || operation === 'Mark as Done'">
            <b-col sm="12">
                <span class="info" v-html="getConfirmApproveTxt()"></span>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
// Components
import UserTicketStatus from './UserTicketStatus.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { TicketUtil } from '@/utils/ticketUtil';

// API & Database
import ticketDAO from '@/database/tickets';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'user-ticket-update-dialog',
    components: {
        UserTicketStatus,
        Loading,
        truncate
    },
    data() {
        return {
            title: 'Start Ticket',
            remarksPlaceholder: 'Please indicate the update made here',
            ticket: {},
            operation: 'Start',
            previousRemarks: '',
            otherConcerns: '',

            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        ticketId() {
            return this.ticket && this.ticket.ticketId ? this.ticket.ticketId : '';
        },
        status() {
            return this.ticket && this.ticket.status ? this.ticket.status : '';
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
    },
    mounted() {
        EventBus.$on('onUpdateTicket', (param) => {
            if (!_.isEmpty(param.ticket)) {
                this.ticket = { ...param.ticket };
                this.operation = param.operation;
                this.otherConcerns = this.ticket.otherConcerns ? this.ticket.otherConcerns : '';

                // reset remarks
                this.previousRemarks = this.ticket.remarks ? this.ticket.remarks : '';
                this.ticket.remarks = '';

                this.reloadLabels();
            }
        });
    },
    methods: {
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },
        isOtherConcerns(ticket) {
            return ticket && ticket.otherConcerns && ticket.otherConcerns.length > 0;
        },
        getConfirmApproveTxt() {
            return 'By clicking <strong>Proceed</strong>, you will no longer able to update any details for this ticket.'
        },
        reloadLabels() {
            if (this.operation === 'Start') {
                this.title = 'Start ' + this.ticketId;
                this.remarksPlaceholder = 'Please indicate the update made here';
            } else if (this.operation === 'Update') {
                this.title = 'Update ' + this.ticketId;
                this.remarksPlaceholder = 'Please indicate the update made here';
            } else if (this.operation === 'Cancel') {
                this.title = 'Cancel ' + this.ticketId;
                this.remarksPlaceholder = 'Please indicate the reason of cancellation here';
            } else if (this.operation === 'Mark as Done') {
                this.title = 'Mark Ticket As Done';
                this.remarksPlaceholder = 'Please indicate the final remarks on this ticket';
            }
        },
        getSuccessMsg() {
            if (this.operation === 'Start') {
                return `Ticket "${this.ticketId}" was started successfully.`
            } else if (this.operation === 'Update') {
                return `Ticket "${this.ticketId}" was updated successfully.`
            } else if (this.operation === 'Cancel') {
                return `Ticket "${this.ticketId}" was cancelled successfully.`
            } else if (this.operation === 'Mark as Done') {
                return `Ticket "${this.ticketId}" was marked as done successfully.`
            }
        },

        updateTicketFields() {
            if (this.operation === 'Start') {
                this.ticket.dateUpdated = DateUtil.getCurrentTimestamp();
                this.ticket.updatedBy = this.loggedUser.id;

                this.ticket.status = 'On-Going';

            } else if (this.operation === 'Update') {
                this.ticket.dateUpdated = DateUtil.getCurrentTimestamp();
                this.ticket.updatedBy = this.loggedUser.id;

                this.ticket.status = 'On-Going';

            } else if (this.operation === 'Cancel') {
                this.ticket.dateUpdated = DateUtil.getCurrentTimestamp();
                this.ticket.updatedBy = this.loggedUser.id;
                this.ticket.dateCancelled = DateUtil.getCurrentTimestamp();
                this.ticket.cancelledBy = this.loggedUser.id;

                this.ticket.status = 'Cancelled';

            } else if (this.operation === 'Mark as Done') {
                this.ticket.dateUpdated = DateUtil.getCurrentTimestamp();
                this.ticket.updatedBy = this.loggedUser.id;
                this.ticket.dateResolved = DateUtil.getCurrentTimestamp();
                this.ticket.resolvedBy = this.loggedUser.id;

                this.ticket.status = 'Done';
            }
        },
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input');
                this.isLoading = false;
                return;
            }

            try {
                this.updateTicketFields();

                let cleanedTicket = TicketUtil.cleanupFields(this.ticket);
                await ticketDAO.saveTicket(cleanedTicket);

                this.$toaster.success(this.getSuccessMsg());
                this.$refs.modal.hide();

                EventBus.$emit('onCloseSaveTicket', this.ticket);

            } catch (error) {
                console.log(error);
                this.$toaster.error(`Error saving ticket "${this.ticketId}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        breakRemarks(remarks, length) {
            return remarks.length > length ? remarks.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : remarks;
        }
    },
    beforeDestroy() {
        EventBus.$off('onUpdateTicket');
    },
}
</script>

<style scoped>
.info {
    font-size: 14px;
    font-style: italic;
    color: #F2940C;
}
</style>