<template>
    <div>
        <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
            @click.stop="row.toggleDetails" class="mr-1 mt-1">
            <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
            <i class="fa fa-eye" v-else></i>
        </b-button>

        <b-button v-show="isAllowedToStart(row.item)" size="sm" v-b-modal.user-ticket-update-dialog
            v-b-tooltip.hover.top="'Start Ticket'" variant="success" @click.stop="updateTicket(row.item, 'Start')"
            class="mr-1 mt-1">
            <i class="fa fa-play"></i>
        </b-button>

        <b-button v-show="isAllowedToEdit(row.item)" size="sm" v-b-modal.user-ticket-update-dialog
            v-b-tooltip.hover.top="'Edit Details'" variant="warning" @click.stop="updateTicket(row.item, 'Update')"
            class="mr-1 mt-1">
            <i class="fa fa-pencil"></i>
        </b-button>

        <b-button v-show="isAllowedToCancel(row.item)" size="sm" v-b-modal.user-ticket-update-dialog
            v-b-tooltip.hover.top="'Cancel Ticket'" variant="danger" @click.stop="updateTicket(row.item, 'Cancel')"
            class="mr-1 mt-1">
            <i class="fa fa-ban"></i>
        </b-button>

        <b-button v-show="isAllowedToMarkAsDone(row.item)" size="sm" v-b-modal.user-ticket-update-dialog
            v-b-tooltip.hover.top="'Mark Ticket As Done'" variant="primary"
            @click.stop="updateTicket(row.item, 'Mark as Done')" class="mr-1 mt-1">
            <i class="fa fa-check"></i>
        </b-button>
    </div>
</template>

<script>
import EventBus from '@/shared/event-bus';

export default {
    name: 'user-ticket-row-actions',
    props: {
        row: {
            type: Object,
            required: true
        },
        isSuperAdmin: {
            type: Boolean,
            required: true
        },
        isViewer: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        updateTicket(ticket, operation) {
            this.$store.commit('SET_CURR_TICKET', ticket);
            EventBus.$emit('onUpdateTicket', { ticket, operation });
        },
        isAllowedToStart(ticket) {
            return ticket.status === 'Pending' && !this.isViewer
        },
        isAllowedToEdit(ticket) {
            return ticket.status === 'On-Going' && !this.isViewer
        },
        isAllowedToCancel(ticket) {
            return (ticket.status === 'Pending' || ticket.status === 'On-Going') && !this.isViewer;
        },
        isAllowedToMarkAsDone(ticket) {
            return ticket.status === 'On-Going' && !this.isViewer;
        }
    }
};
</script>
