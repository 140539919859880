<template>
    <div>
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Primary Details" active>
                    <b-card-text>
                        <b-form-group label="Ticket Id:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="numFont">{{ row.item.ticketId }}</span>
                        </b-form-group>

                        <b-form-group label="Request Type:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.requestType }}
                        </b-form-group>

                        <b-form-group label="Remarks:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="truncate-text">
                                <truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
                                    less="Show Less" type="html" :text="breakRemarks(remarks, 120)" />
                            </span>
                        </b-form-group>

                        <b-form-group label="Status:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <UserTicketStatus :ticket="row.item" />
                        </b-form-group>

                        <b-form-group label="Date Created:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateCreated) }}
                        </b-form-group>
                        <b-form-group v-show="row.item.createdBy && row.item.createdBy.length > 0" label="Created By:"
                            label-cols-sm="3" label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ row.item.createdBy }}
                        </b-form-group>

                        <b-form-group v-show="row.item.status === 'On-Going'" label="Date Updated:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateUpdated) }}
                        </b-form-group>
                        <b-form-group v-show="row.item.status === 'On-Going'" label="Updated By:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ row.item.updatedBy }}
                        </b-form-group>

                        <b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateCancelled) }}
                        </b-form-group>
                        <b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ row.item.cancelledBy }}
                        </b-form-group>

                        <b-form-group v-show="row.item.status === 'Done'" label="Date Resolved:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateResolved) }}
                        </b-form-group>
                        <b-form-group v-show="row.item.status === 'Done'" label="Resolved By:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ row.item.resolvedBy }}
                        </b-form-group>
                        <b-form-group v-show="row.item.status === 'Done'" label="Duration:" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ row.item.duration }}
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <b-tab title="User Details">
                    <b-card-text>
                        <b-form-group label="First Name:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.userDetails.firstName }}
                        </b-form-group>

                        <b-form-group label="Middle Name:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.userDetails.middleName ? row.item.userDetails.middleName : '-' }}
                        </b-form-group>

                        <b-form-group label="Last Name:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.userDetails.lastName }}
                        </b-form-group>

                        <b-form-group label="Position:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.userDetails.position }}
                        </b-form-group>

                        <b-form-group label="Employee No:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="numFont">{{ row.item.userDetails.employeeNo }}</span>
                        </b-form-group>

                        <b-form-group label="Contact No:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="numFont">{{ row.item.userDetails.contactNo }}</span>
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <b-tab title="Branch Details">
                    <b-card-text>
                        <b-form-group label="Region:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.branchDetails.region }}
                        </b-form-group>

                        <b-form-group label="Area:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.branchDetails.area }}
                        </b-form-group>

                        <b-form-group label="Branch Code:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="numFont">{{ row.item.branchDetails.branchCode }}</span>
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <b-tab title="Other Details" v-if="row.item.otherConcerns.length > 0">
                    <b-card-text>
                        <b-form-group label="Other Concerns:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="truncate-text">
                                <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50"
                                    less="Show Less" type="html" :text="breakRemarks(otherConcerns, 25)" />
                            </span>
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <b-tab title="Change Logs">
                    <UserTicketChangeLogsDetailsView :row="row" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
// Components
import UserTicketStatus from './UserTicketStatus.vue';
import UserTicketChangeLogsDetailsView from './UserTicketChangeLogsDetailsView.vue';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import truncate from 'vue-truncate-collapsed';

export default {
    name: 'user-ticket-details-view',
    components: {
        UserTicketStatus,
        UserTicketChangeLogsDetailsView,
        truncate
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    computed: {
        remarks() {
            return this.row.item.remarks ? this.row.item.remarks : '-'
        },
        otherConcerns() {
            return this.row.item.otherConcerns ? this.row.item.otherConcerns : '-'
        }
    },
    methods: {
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        breakRemarks(remarks, length) {
            return remarks.length > length ? remarks.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : remarks;
        }
    },
};
</script>